import { apiSignIn, apiSignOut, apiSignUp } from '@/services/AuthService'
import {
    setUser,
    signInSuccess,
    signOutSuccess,
    useAppSelector,
    useAppDispatch,
} from '@/store'
import appConfig from '@/configs/app.config'
import { REDIRECT_URL_KEY } from '@/constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import type {
    SignInCredential,
    SignUpCredential,
    UserData,
} from '@/@types/auth'

type Status = 'success' | 'failed'

const adminWebsiteUrl = import.meta.env.VITE_ADMIN_WEBSITE_URL

function useAuth() {
    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useAppSelector((state) => state.auth.session)

    const signIn = async (
        values: SignInCredential
    ): Promise<{ status: Status; message: string } | undefined> => {
        try {
            const resp = await apiSignIn(values)
            if (!resp.data.error && resp.data.data) {
                if (resp?.data?.data?.user_type === 'administrator') {
                    window.location.href = adminWebsiteUrl
                } else {
                    const {
                        access_token,
                        user_data,
                        user_type,
                        country,
                        mobile_verified,
                        email_verified,
                        account_verified_at,
                    } = resp.data.data

                    const user = {
                        name: user_data.name,
                        email: user_data.email,
                        mobile: user_data.mobile,
                        country: country,
                        profiles: user_type,
                        mobile_verified: mobile_verified,
                        authority: user_type,
                        email_verified: email_verified,
                        account_verified_at: account_verified_at,
                    }

                    dispatch(signInSuccess(access_token))
                    if (resp.data.data) {
                        dispatch(setUser(user))
                    }
                    return {
                        status: 'success',
                        message: '',
                    }
                }
            } else {
                return {
                    status: 'failed',
                    message: resp.data.message,
                }
            }
        } catch (errors: any) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }
    const signUp = async (values: SignUpCredential) => {
        try {
            const resp = await apiSignUp(values)
            const data = resp.data.data as {
                access_token: string
                user_data: UserData
                profiles: 'seeker' | 'provider'
                mobile_verified: boolean
            }
            if (data) {
                const token = data?.access_token
                const {
                    user_data,
                    user_type,
                    mobile_verified,
                    email_verified,
                    account_verified_at,
                } = data
                dispatch(signInSuccess(token))
                if (data?.user_data) {
                    dispatch(
                        setUser({
                            name: user_data.name,
                            email: user_data.email,
                            mobile: user_data.mobile,
                            profiles: user_type,
                            mobile_verified: mobile_verified,
                            email_verified: email_verified,
                            authority: user_type,
                            account_verified_at,
                        })
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors: any) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(signOutSuccess())
        localStorage.removeItem('admin')
        dispatch(
            setUser({
                name: '',
                email: '',
                profiles: '',
                authority: '',
                mobile_verified: false,
                email_verified: false,
            })
        )
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        try {
            await apiSignOut()
            handleSignOut()
        } catch (error) {
            handleSignOut()
        }
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
